<template>
    <div class="title">{{ page.name }}</div>
    <div class="typography" v-html="page.text"></div>
</template>

<script>
import store from "@/store/index.js"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch('FETCH_STATIC_PAGE', to.name).then(() => next())
    },
    computed: {
        page() {
            return this.$store.state.about.page
        }
    }
}
</script>
